label.weui-cell > *,
label.weui-agree > * {
  pointer-events: none;
}
.weui-skin_android .weui-actionsheet_toggle {
  opacity: 1 !important;
  top: 50% !important;
  bottom: auto !important;
}
.weui-skin_android .weui-actionsheet {
  opacity: 0;
  transition: opacity .3s;
  top: 150%;
  bottom: 0;
}
.weui-toptips_default {
  background-color: #B2B2B2;
}
.weui-toptips_info {
  background-color: #586C94;
}
.weui-toptips_primary {
  background-color: #1AAD19;
}
.react-weui-swiper__container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.react-weui-swiper__wrapper {
  overflow: hidden;
}
.react-weui-swiper__pagination {
  position: absolute;
  text-align: center;
  transition: all .3s;
  z-index: 10;
}
.react-weui-swiper__pagination-bullet {
  border-radius: 100%;
  background: #eee;
  width: 8px;
  height: 8px;
  display: inline-block;
}
.react-weui-swiper__pagination-bullet.active {
  background: #1AAD19;
}
.react-weui-swiper__container.react-weui-swiper__container-horizontal .react-weui-swiper__pagination {
  bottom: 10px;
  width: 100%;
}
.react-weui-swiper__container.react-weui-swiper__container-horizontal .react-weui-swiper__pagination-bullet {
  margin: 0 5px;
}
.react-weui-swiper__container.react-weui-swiper__container-vertical .react-weui-swiper__pagination {
  right: 10px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}
.react-weui-swiper__container.react-weui-swiper__container-vertical .react-weui-swiper__pagination-bullet {
  margin: 5px 0;
  display: block;
}
.weui-popup {
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  backface-visibility: hidden;
  z-index: 5000;
  width: 100%;
  background-color: #EFEFF4;
  transition: transform .3s;
}
.weui-popup_toggle {
  transform: translate(0, 0);
}
.weui-popup__hd {
  display: flex;
  padding: 10px 15px;
  background-color: #fbf9fe;
  position: relative;
  text-align: center;
}
.weui-popup__hd:after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #E5E5E5;
  color: #E5E5E5;
  transform-origin: 0 100%;
  transform: scaleY(0.5);
}
.weui-popup__action {
  display: block;
  flex: 1;
  color: #586C94;
}
.weui-popup__action:first-child {
  text-align: left;
}
.weui-popup__action:last-child {
  text-align: right;
}
.react-weui-ptr {
  overflow: hidden;
}
.react-weui-ptr__loader {
  pointer-events: none;
  font-weight: bold;
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: stretch;
}
.react-weui-ptr__content {
  height: 100%;
  overflow: scroll;
}
.react-weui-infiniteloader {
  overflow: scroll;
}
.react-weui-infiniteloader__content {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}
.weui-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  height: 100%;
  z-index: 1024;
  transition: all .2s ease;
  animation-duration: .3s;
}
@keyframes pageInRight {
  from {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes pageOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
  }
}
label.weui-cell > *,
label.weui-agree > * {
  pointer-events: none;
}
.weui-skin_android .weui-actionsheet_toggle {
  opacity: 1 !important;
  top: 50% !important;
  bottom: auto !important;
}
.weui-skin_android .weui-actionsheet {
  opacity: 0;
  transition: opacity .3s;
  top: 150%;
  bottom: 0;
}
.weui-toptips_default {
  background-color: #B2B2B2;
}
.weui-toptips_info {
  background-color: #586C94;
}
.weui-toptips_primary {
  background-color: #1AAD19;
}
.react-weui-swiper__container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.react-weui-swiper__wrapper {
  overflow: hidden;
}
.react-weui-swiper__pagination {
  position: absolute;
  text-align: center;
  transition: all .3s;
  z-index: 10;
}
.react-weui-swiper__pagination-bullet {
  border-radius: 100%;
  background: #eee;
  width: 8px;
  height: 8px;
  display: inline-block;
}
.react-weui-swiper__pagination-bullet.active {
  background: #1AAD19;
}
.react-weui-swiper__container.react-weui-swiper__container-horizontal .react-weui-swiper__pagination {
  bottom: 10px;
  width: 100%;
}
.react-weui-swiper__container.react-weui-swiper__container-horizontal .react-weui-swiper__pagination-bullet {
  margin: 0 5px;
}
.react-weui-swiper__container.react-weui-swiper__container-vertical .react-weui-swiper__pagination {
  right: 10px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}
.react-weui-swiper__container.react-weui-swiper__container-vertical .react-weui-swiper__pagination-bullet {
  margin: 5px 0;
  display: block;
}
.weui-popup {
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  backface-visibility: hidden;
  z-index: 5000;
  width: 100%;
  background-color: #EFEFF4;
  transition: transform .3s;
}
.weui-popup_toggle {
  transform: translate(0, 0);
}
.weui-popup__hd {
  display: flex;
  padding: 10px 15px;
  background-color: #fbf9fe;
  position: relative;
  text-align: center;
}
.weui-popup__hd:after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #E5E5E5;
  color: #E5E5E5;
  transform-origin: 0 100%;
  transform: scaleY(0.5);
}
.weui-popup__action {
  display: block;
  flex: 1;
  color: #586C94;
}
.weui-popup__action:first-child {
  text-align: left;
}
.weui-popup__action:last-child {
  text-align: right;
}
.react-weui-ptr {
  overflow: hidden;
}
.react-weui-ptr__loader {
  pointer-events: none;
  font-weight: bold;
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: stretch;
}
.react-weui-ptr__content {
  height: 100%;
  overflow: scroll;
}
.react-weui-infiniteloader {
  overflow: scroll;
}
.react-weui-infiniteloader__content {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}
.weui-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  height: 100%;
  z-index: 1024;
  transition: all .2s ease;
  animation-duration: .3s;
}
@keyframes pageInRight {
  from {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes pageOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0.01;
    transform: translate3d(100%, 0, 0);
  }
}
