.adm-popover {
  --z-index: var(--adm-popover-z-index, 1030);
  --background: #ffffff;
  --arrow-size: 8px;
  --content-padding: 8px 12px;
  color: #333333;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index);
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
          user-select: text;
  animation: none;
}
.adm-popover.adm-popover-dark {
  --background: rgba(0, 0, 0, 0.75);
  --adm-color-text: #ffffff;
  color: #ffffff;
}
.adm-popover.adm-popover-dark .adm-popover-inner {
  box-shadow: none;
}
.adm-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.adm-popover-hidden {
  display: none;
}
.adm-popover-inner {
  background-color: var(--background);
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(51, 51, 51, 0.2);
  font-size: var(--adm-font-size-7);
  width: -webkit-max-content;
  width: max-content;
  min-width: 32px;
  max-width: calc(100vw - 24px);
  overflow-y: hidden;
}
.adm-popover-inner-content {
  padding: var(--content-padding);
}
.adm-popover-arrow {
  position: absolute;
  display: block;
  height: var(--arrow-size);
  width: var(--arrow-size);
  overflow: visible;
  background: transparent;
}
.adm-popover-arrow-icon {
  display: block;
  height: var(--arrow-size);
  width: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(var(--arrow-icon-rotate));
}
.adm-popover .adm-popover-arrow {
  color: var(--background);
}
