.am-result {
  position: relative;
  text-align: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 21px;
  background-color: #fff;
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-result {
    border-bottom: none;
  }
  html:not([data-scale]) .am-result::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-result::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.am-result .am-result-pic {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
  background-size: 60px 60px;
}
.am-result .am-result-title,
.am-result .am-result-message {
  font-size: 21px;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
}
.am-result .am-result-title {
  margin-top: 15px;
  line-height: 1;
}
.am-result .am-result-message {
  margin-top: 9px;
  line-height: 1.5;
  font-size: 16px;
  color: #888;
}
.am-result .am-result-button {
  padding: 0 15px;
  margin-top: 15px;
}
